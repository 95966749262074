<script setup lang="ts">
import { mdToHtml } from '~/lib/markdown'

const props = defineProps<{ transformText: string }>()

const html = mdToHtml(props.transformText)
</script>

<template>
  <div
    v-dompurify-html="html"
    class="prose w-full max-w-none text-mcd-black lg:prose-lg prose-strong:text-mcd-black"
  ></div>
</template>
