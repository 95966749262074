<script setup lang="ts">
import type { Component } from 'vue'
import BlockCard from './BlockCard.vue'
import BlockRichText from './BlockRichText.vue'
import BlockCarouselWide from './BlockCarouselWide.vue'
import BlockAccordion from './BlockAccordion.vue'
import BlockCollapsableMenu from './BlockCollapsableMenu.vue'
import BlockFeaturedTile from './BlockFeaturedTile.vue'
import BlockBackgroundTiles from './BlockBackgroundTiles.vue'
import BlockMarketingCards from './BlockMarketingCards.vue'
import BlockTextFullScreen from './BlockTextFullScreen.vue'
import BlockHeaderTile from './BlockHeaderTile.vue'
import BlockTileBannerFull from './BlockTileBannerFull.vue'
import BlockSliderFullTexts from './BlockSliderFullTexts.vue'
import BlockScrollCards from './BlockScrollCards.vue'
import BlockBannerTiles from './BlockBannerTiles.vue'
import BlockContactForm from './BlockContactForm.vue'
import BlockRedirection from './BlockRedirection.vue'
import { Block } from '~/lib/services/content/content.blocks.dtos'

defineProps<{ block: Block; critical: boolean }>()

const blockComponents: Record<Block['__component'], Component> = {
  'block.card': BlockCard,
  'block.rich-text': BlockRichText,
  'block.carousel-wide': BlockCarouselWide,
  'block.accordion': BlockAccordion,
  'block.collapsable-menu': BlockCollapsableMenu,
  'block.featured-tile': BlockFeaturedTile,
  'block.background-tiles': BlockBackgroundTiles,
  'block.marketing-cards': BlockMarketingCards,
  'block.text-full-screen': BlockTextFullScreen,
  'block.header-tile': BlockHeaderTile,
  'block.tile-banner-full': BlockTileBannerFull,
  'block.slider-full-texts': BlockSliderFullTexts,
  'block.banner-tiles': BlockBannerTiles,
  'block.scroll-cards': BlockScrollCards,
  'block.contact-form': BlockContactForm,
  'block.redirection': BlockRedirection,
}
</script>

<template>
  <component :is="blockComponents[block.__component]" :block="block" />
</template>
