import { VueReCaptcha, useReCaptcha } from 'vue-recaptcha-v3'
import { publicConfig } from '~/config/public'

export function useRecaptcha() {
  const { vueApp } = useNuxtApp()

  vueApp.use(VueReCaptcha, {
    siteKey: publicConfig.recaptcha.siteKey,
    loaderOptions: { autoHideBadge: true },
  })
  const { executeRecaptcha, recaptchaLoaded } = useReCaptcha()!

  return async (action: any) => {
    await recaptchaLoaded()
    return await executeRecaptcha(action)
  }
}
