export const ContactFormSubject = {
  SUGGESTION: 'suggestion',
  INQUIRE: 'inquire',
  OPEN_DOORS: 'openDoors',
  CRITICISM: 'criticism',
  CLAIM: 'claim',
  PRAISE: 'praise',
  PERSONAL_DATA: 'personalData',
  OTHER: 'other',
} as const

export type ContactForm = {
  firstname: string
  lastname: string
  email: string
  subject: string
  message: string
  newsletterAccepted: boolean
  // other optional
  city?: string
  phone?: string
  local?: string
  date?: string
  time?: string
  // recaptcha
  recaptcha?: string
}
